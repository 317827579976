<template>
  <div class="container">
    <div v-if="success">
      Organization successfully created
    </div>
    <form @submit.prevent="createOrg" id="createOrgForm" v-if="!success" novalidate>
      <h4>Register a new organization</h4>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="orgNameInput" class="form-label float-start">Organization name*</label>
          <input v-bind:class="{'form-control':true, 'is-invalid' : !orgNameValid}"
                 v-on:blur="validateOrgName"
                 id="orgNameInput" v-model="orgName" placeholder="Name of your organization"
                 required/>
          <div class="invalid-feedback">Organization name is required</div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="orgEmailInput" class="form-label float-start">Organization email*</label>
          <input id="orgEmailInput" v-bind:class="{'form-control':true, 'is-invalid' : !orgEmailValid}"
                 v-on:blur="validateEmail" type="email"
                 v-model="orgEmail" placeholder="Organization email address"
                 required/>
          <div class="invalid-feedback">Email is invalid</div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="countryInput" class="form-label float-start">Country*</label>
          <input id="countryInput" v-bind:class="{'form-control':true, 'is-invalid' : !countryValid}"
                 v-on:blur="validateCountry" type="text" v-model="country" placeholder="Country" required/>
          <div class="invalid-feedback">Country is required</div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="stateInput" class="form-label float-start">State or region</label>
          <input class="form-control" id="stateInput" type="text" v-model="state" placeholder="State or region"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="cityInput" class="form-label float-start">City</label>
          <input class="form-control" id="cityInput" type="text" v-model="city" placeholder="City"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="postalCodeInput" class="form-label float-start">Postal code</label>
          <input class="input form-control" id="postalCodeInput" type="text" v-model="postalCode"
                 placeholder="Postal code"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="addressInput" class="form-label float-start">Address</label>
          <input class="input form-control" id="addressInput" type="text" v-model="address" placeholder="Address"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="phoneNumberInput" class="form-label float-start">Phone number</label>
          <input class="input form-control" id="phoneNumberInput" type="tel" v-model="phoneNumber"
                 placeholder="Phone number"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <label for="taxCodeInput" class="form-label float-start">Tax id</label>
          <input class="input form-control" id="taxCodeInput" type="text" v-model="taxCode" placeholder="Tax id"/>
        </div>
      </div>
      <div v-if="hasValidationErrors" class="errorMessage">
        There are errors in the form. Please review and try again.
      </div>
      <div v-if="errorMessage" class="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="spinner-border" role="status" v-if="isLoading">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-if="!isLoading">
        <button class="button">Register</button>
      </div>
    </form>

  </div>
</template>

<script>

import {AttiniApi} from "@/AttiniApi";

export default {
  name: "Create Organization",
  data() {
    return {
      orgEmail: '',
      orgEmailValid: true,
      orgName: '',
      orgNameValid: true,
      country: '',
      countryValid: true,
      state: '',
      city: '',
      postalCode: '',
      address: '',
      taxCode: '',
      phoneNumber: '',
      isLoading: false,
      success: false,
      errorMessage: '',
      hasValidationErrors: false
    }
  },
  methods: {
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.orgEmailValid = re.test(this.orgEmail.toLowerCase());
      return this.orgEmailValid;
    },
    validateCountry() {
      this.countryValid = this.country;
      return this.countryValid;
    },
    validateOrgName() {
      this.orgNameValid = this.orgName;
      return this.orgNameValid;
    },
    validateForm(){
      const email = this.validateEmail();
      const country = this.validateCountry();
      const orgName = this.validateOrgName();

      return email && country && orgName;
    },
    async createOrg() {
      if (!this.validateForm()) {
        this.hasValidationErrors = true;
        return;
      }
      this.hasValidationErrors = false;
      this.errorMessage = false;
      this.isLoading = true;
      AttiniApi.post("organization/create",  {
        organizationEmail: this.orgEmail,
        organizationName: this.orgName,
        country: this.country,
        state: this.state,
        city: this.city,
        postalCode: this.postalCode,
        address: this.address,
        taxCode: this.taxCode,
        phoneNumber: this.phoneNumber
      })
          .then(() => {
            this.isLoading = false;
            this.success = true;
          })
          .catch(() => {
            this.isLoading = false;
            this.errorMessage = "Could not create organization. Please try again or contact Attini at support@attini.io"
          })
    }
  }
}
</script>

<style scoped>
#createOrgForm > * {
  margin: 20px 0;
}

</style>