<template>
  <div class="container">
    <div>
      <h4>Create new account group</h4>
      <p>
        Account groups are a way for you to group accounts. You can create one or more tokens for different account
        groups.
        For example you can have one group for your development environment accounts and one for your production
        environment
        account.
        To use Attini above the free tier you need to have at least one account group.
      </p>
    </div>
    <div class="spinner-border" role="status" v-if="isLoading">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-if="!submitted && !isLoading && hasOrganization">
      <form @submit.prevent="createAccountGroup" novalidate>
        <div class="row justify-content-md-center">
          <div class="col-md-5 col-md-3">
            <input v-bind:class="{'form-control':true, 'is-invalid' : !nameValid}"
                   type="text"
                   v-model="name"
                   placeholder="New account group name..."
                   required
            />

          </div>
        </div>
        <div v-if="!nameValid" class="errorMessage">
          Please enter a name for your account group.
        </div>
        <div v-if="errorMessage" class="errorMessage">
          {{ errorMessage }}
        </div>
        <button class="button">Create</button>
      </form>
    </div>
    <div v-if="!isLoading && !hasOrganization">
      Could not find any organization connected to this user.
      Create one
      <router-link to="/create-organization">here</router-link>
      before you can create an account group.
    </div>
  </div>

</template>

<script>
import {AttiniApi} from "@/AttiniApi";


export default {
  name: "CreateAccountGroup",
  data() {
    return {
      name: '',
      nameValid: true,
      submitted: false,
      errorMessage: '',
      isLoading: true,
      hasOrganization: true
    }
  },
  created() {
    AttiniApi.get("organization")
        .then(() => {
          this.hasOrganization = true;
          this.isLoading = false;
        })
        .catch(() => {
          this.hasOrganization = false;
          this.isLoading = false;
        });
  },
  methods: {

    async createAccountGroup() {
      if (!this.name) {
        this.nameValid = false;
        return;
      }
      this.errorMessage = false;
      this.isLoading = true;
      AttiniApi.post("account-group", {name: this.name})
          .then(() => {
            this.isLoading = false;
          })
          .then(() => this.$router.push("/account-groups"))
          .catch(() => {
            this.errorMessage = "Could not create account group, please try again. If the problem persists please contact Attini at support@attini.io"
            this.isLoading = false;

          })
    }
  }
}
</script>

<style scoped>

</style>