<template>
  <div class="container">
    <div>
      <h4>Account groups</h4>
      <p>
        Account groups are a way for you to group accounts. You can create one or more tokens for different account
        groups.
        For example you can have one group for your development environment accounts and one for your production
        environment
        account.
        To use Attini above the free tier you need to have at least one account group.
      </p>
    </div>

    <div style="margin-top: 40px">
      <div v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="spinner-border" role="status" v-if="isLoading">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-if="!isLoading && accountGroups.length ===0">
        There is no account groups connected to this organization. Create one
        <router-link to="/create-account-group">here</router-link>
      </div>
      <div class="dropdown" v-if="accountGroups.length !== 0">
        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span style="padding-left: 20px; padding-right: 20px">
          {{ dropdownTitle }}
        </span>
        </button>
        <ul class="dropdown-menu">
          <li class="dropdown-item" v-for="group in accountGroups" v-bind:key="group.accountGroupId" style="width: 100%"
              @click="switchAccountGroup(group)">
            {{ group.name }}
          </li>
        </ul>
      </div>
      <ManageTokens v-if="accountGroup.accountGroupId" :accountGroup="accountGroup"></ManageTokens>
    </div>

  </div>
  <div style="padding-top: 50px" v-if="accountGroup.accountGroupId">
    <h5>Accounts</h5>
    <p>
      Bellow are your AWS accounts that are using a token from this group.
      When a new account is added you can approve or deny it here.
    </p>
    <div class="spinner-border" role="status" v-if="isLoadingAccounts">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div>
      <ul class="list-group mx-auto justify-content-center" style="max-width: 500px">
        <li class="list-group-item d-flex justify-content-between" v-for="account in accounts"
            v-bind:key="account.accountId">
          <div>
            {{ account.accountId }}
          </div>
          <div v-if="!isUpdatingAccount">
            <div v-if="account.status === 'PENDING'">
              <button type="button" class="btn btn-success btn-sm" style="margin-right: 10px"
                      @click="approveAccount(account)">Approve
              </button>
              <button type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#confirmDeny"
                      @click="setAccountForDenial(account)">Deny
              </button>
            </div>
            <div v-if="account.status === 'APPROVED'" style="color: green;">
              Approved
            </div>
            <div v-if="account.status === 'DENIED'" style="color: red;">
              Denied
              <button type="button" class="btn btn-success btn-sm" style="margin-left: 10px"
                      @click="approveAccount(account)">Approve
              </button>
            </div>
          </div>

          <div class="spinner-border" role="status" v-if="isUpdatingAccount">
            <span class="visually-hidden">Loading...</span>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="accountGroup.accountGroupId && accounts.length === 0 && !isLoadingAccounts">
      <p>
        There are currently no AWS account connected to this account group. Create a token and include it in your attini
        setup configuration.
        The account will be added to this group the first time you use Attini from the AWS account.
      </p>
    </div>
    <div v-if="accounts.length !==0">
      <button class="button" style="margin-bottom: 60px" data-bs-toggle="modal"
              data-bs-target="#deleteAccountGroupInfo"> Remove account group
      </button>
    </div>
    <div v-if="accounts.length === 0">
      <button class="button" style="margin-bottom: 60px" @click="removeAccountGroup()"> Remove account group</button>
    </div>
    <div class="modal fade" id="confirmDeny" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to deny the Account?</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>
              Someone has used one of your tokens from this account. If you deny the account it will be blocked from
              using Attini.
              If it is not your account it means that one of your tokens has been leaked outside your organization and
              the tokens should
              removed to prevent other accounts from sending billing data to your organization.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="denyAccount()">Deny account
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="deleteAccountGroupInfo" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteAccountGroupInfoLabel">Can not remove account group</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>
              In order to remove an account group you first need to migrate any connected accounts to a new group.
              Do this by changing the token for the account in the attini framework configuration. After
              you run a deployment plan with the new token the account will change account group automatically after a
              few minutes.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageTokens from "@/views/ManageTokens";
import {AttiniApi} from "@/AttiniApi";

export default {
  name: "AccountGroups",
  components: {ManageTokens},
  data() {
    return {
      accountGroups: [],
      errorMessage: '',
      isLoading: true,
      isLoadingAccounts: false,
      isUpdatingAccount: false,
      accountGroup: {},
      dropdownTitle: 'select account group',
      accounts: [],
      accountIdToDeny: ''
    }
  },
  methods: {

    async switchAccountGroup(accountGroup) {
      this.setSelected(accountGroup).then(() => this.getAccounts());
    },

    async setSelected(accountGroup) {
      this.accountGroup = accountGroup;
      this.dropdownTitle = accountGroup.name;
    },
    async removeAccountGroup() {
      this.isLoading = true;
      this.accountGroups = [];
      AttiniApi.delete("account-group/"+this.accountGroup.accountGroupId)
          .then(() => this.dropdownTitle = 'select account group')
          .then(() =>  this.accountGroup = {})
          .then(() => this.getAccountGroups());
    },
    async getAccounts() {
      this.isLoadingAccounts = true;
      this.accounts = [];
      AttiniApi.get("account/" + this.accountGroup.accountGroupId)
          .then(value => this.accounts = value.data)
          .then(() => this.isLoadingAccounts = false)
          .catch(() => {
            //TODO Better placement of this error message
            this.errorMessage = " could not load accounts, please try again or contact Attini at support@attini.io";
            this.isLoadingAccounts = false;
          });
    },
    async approveAccount(account) {
      this.isUpdatingAccount = true;
      AttiniApi.post("account/update", {
        accountId: account.accountId,
        accountStatus: "APPROVED"
      })
          .then(() => this.getAccounts())
          .then(() => this.isUpdatingAccount = false);
    },

    async setAccountForDenial(account) {
      this.accountIdToDeny = account.accountId;
    },

    async denyAccount() {
      this.isUpdatingAccount = true;
      AttiniApi.post("account/update", {
        accountId: this.accountIdToDeny,
        accountStatus: "DENIED"
      })
          .then(() => this.getAccounts())
          .then(() => this.isUpdatingAccount = false);
    },
    async getAccountGroups() {
      AttiniApi.get("account-group")
          .then(value => {
            this.accountGroups = value.data;
            if (this.accountGroups.length === 1) {
              this.switchAccountGroup(this.accountGroups[0])
            }
            this.isLoading = false;

          }).catch(() => {
        this.errorMessage = "Could not get account account groups. Please try again or contact Attini at support@attini.io"
        this.isLoading = false;
      })
    },
  },
  created() {
    this.getAccountGroups();

  }
}
</script>

<style scoped>
.btn-secondary {
  background-color: #006A6D;
  color: white;
}

</style>