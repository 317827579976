<template>
  <div class="container">
    <div style="padding-top: 50px">
      <h5>Current tokens</h5>
      <div>
        <p>
          Tokens can be added to an Attini installation in your AWS Account in order to connect the account to your organization.
          After an account is added you will get an email informing you of the new account and asking you to log in an approve it here.
          Never approve an account that does not belong to you. After the account has been approved you can see your usage an cost for
          the account under the Billing page. To add the token to your installation using the Attini CLI simply run: <br>
          <code>
            attini setup --license-token {your_token}
          </code>
        </p>
      </div>
      <ul class="list-group mx-auto justify-content-center" style="max-width: 500px">
        <div v-if="!isLoadingToken && tokens.length === 0">
          There are currently no tokens created for this account group.
        </div>
        <li class="list-group-item d-flex justify-content-between" v-for="token in tokens" v-bind:key="token">
          <div>
            {{ token }}
          </div>
          <div>
            <button type="button" v-if="!isDeletingToken || tokenToDelete !== token" class="btn btn-danger btn-sm"
                    @click="setTokenForDelete(token)" data-bs-toggle="modal" data-bs-target="#confirmDelete">Remove
              token
            </button>
          </div>
          <div class="spinner-border" role="status" v-if="isDeletingToken && tokenToDelete === token">
            <span class="visually-hidden">Loading...</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="spinner-border" role="status" v-if="isLoadingToken" style="margin-top: 20px">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div>
      <button class="button" @click="createToken"> Create a new token</button>
    </div>
    <div class="modal fade" id="confirmDelete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Confirm</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure you want to remove the token? Any AWS account that is configured with this token will need
            a new token in order to keep using Attini above the free tier.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="removeToken()">Delete token
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AttiniApi} from "@/AttiniApi";

export default {
  name: "ManageTokens",
  created() {
    this.getTokens();
  },
  data() {
    return {
      tokens: [],
      isLoadingToken: true,
      tokenToDelete: '',
      isDeletingToken: false
    }
  },
  props: {
    accountGroup: Object
  },
  watch: {
    accountGroup: function () {
      this.tokens = [];
      this.isLoadingToken = true;
      this.getTokens();
    }
  },
  methods: {
    async createToken() {
      this.isLoadingToken = true;
      AttiniApi.post("token", {accountGroupId: this.accountGroup.accountGroupId})
          .then(() => {
            this.getTokens();
          })
          .catch(() => {
            this.errorMessage = "Could not create organization"
          })
    },

    async setTokenForDelete(token) {
      this.tokenToDelete = token;
    },

    async removeToken() {
      this.isDeletingToken = true;
      AttiniApi.delete("token/"+this.tokenToDelete)
          .then(() => {
            this.getTokens();
          })
          .then(() => this.isDeletingToken = false)
          .catch(() => {
            this.isDeletingToken = false;
            this.errorMessage = "Could not create organization"
          })
    },

    async getTokens() {
      AttiniApi.get("token/" + this.accountGroup.accountGroupId)
          .then((result) => {
            this.tokens = result.data;
            this.isLoadingToken = false;
          })
          .catch(() => {
            this.errorMessage = "Could not get tokens";
            this.isLoadingToken = false;
          })
    }
  }
}
</script>

<style scoped>

</style>